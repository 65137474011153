<template>
  <ion-row class="title-part-padding-form-driver mb-5">
    <ion-col class="row-align-center">
      <ion-label class="title-part-form-driver">A été livré</ion-label>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <div class="align-line-icon-badge">
        <ion-img
          class="width-icon-img-30"
          src="/assets/icon/material.svg"
        ></ion-img>
        <div class="align-chip-form ml-10">
          <ion-chip
            color="tertiary"
            outline="true"
            v-for="material in materials"
            :key="material"
            :disabled="oldRentalCouponSending"
          >
            <ion-label>{{ material }}</ion-label>
            <ion-icon
              @click="deleteMaterial(material)"
              :icon="closeCircle"
            ></ion-icon>
          </ion-chip>

          <ion-icon
            v-if="oldRentalCouponSending"
            class="width-icon-img-30 ml-5 color-yellow-dark opacity4"
            @click="addMaterial"
            :icon="addCircle"
          ></ion-icon>

          <ion-icon
            v-else
            class="width-icon-img-30 ml-5 color-yellow-dark"
            @click="addMaterial"
            :icon="addCircle"
          ></ion-icon>
        </div>
      </div>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <div class="align-line-icon-badge mt-5">
        <ion-img
          class="width-icon-img-25"
          src="/assets/icon/rounds.svg"
        ></ion-img>
        <ion-select
          class="mr-10"
          @ionChange="onChangeRounds($event.target.value)"
          :value="rounds"
          placeholder="Aucun de sélectionnée"
          ok-text="Valider"
          cancel-text="Annuler"
          :disabled="oldRentalCouponSending"
        >
          <ion-select-option
            v-for="round in allRoundsType"
            :value="round"
            :key="round"
          >
            {{ round.name }}
          </ion-select-option>
        </ion-select>
      </div>
    </ion-col>
  </ion-row>

  <ion-row class="title-part-padding-form-driver">
    <ion-col size="9" class="row-align-center">
      <ion-label
        class="title-part-form-driver"
        @click="onChangeToggleQuantity(!toggleQuantityTools)"
        >Quantité</ion-label
      >
    </ion-col>
    <ion-col class="justify-content-end">
      <ion-toggle
        @ionChange="onChangeToggleQuantity($event.target.checked)"
        :checked="toggleQuantityTools"
        mode="md"
        color="dark"
      ></ion-toggle>
    </ion-col>
  </ion-row>
  <ion-row v-for="obj in tonsByRound" :key="obj" v-show="toggleQuantityTools">
    <ion-col class="row-align-center pl-5" size="4">
      <ion-label>{{ obj.name }}</ion-label>
    </ion-col>
    <ion-col size="4">
      <ion-input
          class="input-mini-form-driver pl-5"
          name="tons-by-round"
          @ionChange="onChangeTonsByRound(obj.name, $event.target.value)"
          input="decimal"
          inputmode="decimal"
          min="0"
          :value="obj.value"
          clear-on-edit="true"
          :disabled="oldRentalCouponSending"
      ></ion-input>
    </ion-col>
  </ion-row>
  <ion-row
    v-if="
      this.rounds.name !== 'À la journée' &&
      toggleQuantityTools &&
      !this.rounds.isCustom
    "
  >
    <ion-col class="row-align-center pl-5" size="4">
      <ion-label>Total</ion-label>
    </ion-col>
    <ion-col size="4">
      <ion-input
          class="input-mini-form-driver no-border pl-5"
          name="tons-by-round"
          input="decimal"
          inputmode="decimal"
          min="0"
          :value="totalTonsByRound"
          clear-on-edit="true"
          :disabled="true"
      ></ion-input>
    </ion-col>
  </ion-row>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonLabel,
  IonImg,
  IonChip,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonToggle,
  popoverController,
} from '@ionic/vue';
import { closeCircle, addCircle } from 'ionicons/icons';
import PopoverAddMaterial from '@/components/driver/popover/PopoverAddMaterial';
export default {
  name: 'DeliveryDetails',
  components: {
    IonRow,
    IonCol,
    IonLabel,
    IonImg,
    IonChip,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonToggle,
  },
  props: [
    'materials',
    'rounds',
    'tonsByRound',
    'allRoundsType',
    'toggleQuantityTools',
    'oldRentalCouponSending',
  ],
  setup() {
    return {
      closeCircle,
      addCircle,
    };
  },
  methods: {
    onChangeToggleQuantity(value) {
      this.$emit('update:toggleQuantityTools', value);
    },
    onChangeRounds(obj) {
      this.$emit('update:rounds', obj);
      this.updateTonsByRound(obj);
    },
    onChangeTonsByRound(name, value) {
      let newTonsByRound = this.tonsByRound;

      for (const key in newTonsByRound) {
        if (newTonsByRound[key].name === name) {
          newTonsByRound[key].value = value;
          this.$emit('update:tonsByRound', newTonsByRound);
          return;
        }
      }
    },
    deleteMaterial(materialToRemove) {
      let newMaterials = this.materials;

      const index = newMaterials.indexOf(materialToRemove);
      if (index > -1) {
        newMaterials.splice(index, 1);
      }
      this.$emit('update:materials', newMaterials);
    },

    async addMaterial() {
      if (this.oldRentalCouponSending) {
        return;
      }

      const popover = await popoverController.create({
        component: PopoverAddMaterial,
        componentProps: {
          closePopover: newMaterial => {
            let newMaterials = this.materials || [];
            newMaterials.push(newMaterial);
            this.$emit('update:materials', newMaterials);
            popover.dismiss();
          },
        },
      });
      await popover.present();

      await popover.onDidDismiss();
    },
    updateTonsByRound(obj) {
      const newTonsByRound = {};
      const isAllDay = obj.name === 'À la journée';
      const isCustom = obj.isCustom;
      const length = isCustom || isAllDay ? 1 : obj.value;

      for (let i = 0; i < length; i++) {
        let name = `Tour ${i + 1}`;
        if (isAllDay || isCustom) {
          name = 'Total';
        }

        newTonsByRound[i] = {
          name: name,
          value: this.tonsByRound[i] ? this.tonsByRound[i].value : '',
        };
      }

      this.$emit('update:tonsByRound', newTonsByRound);
    },
  },

  computed: {
    totalTonsByRound() {
      let total = 0;
      Object.values(this.tonsByRound).forEach((round) => {
        if (round.value) {
          total += parseFloat(round.value);
        }
      });

      return total;
    }
  }
};
</script>