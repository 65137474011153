<template>
  <ion-row class="title-part-padding-form-driver mb-5">
    <ion-col class="row-align-center">
      <ion-label class="title-part-form-driver">A été transféré</ion-label>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <div class="align-line-icon-badge">
        <ion-img
          class="width-icon-img-30"
          src="/assets/icon/machine.svg"
        ></ion-img>
        <div class="align-chip-form ml-10">
          <ion-chip
            color="tertiary"
            outline="true"
            v-for="machine in machines"
            :key="machine"
            :disabled="oldRentalCouponSending"
          >
            <ion-label>{{ machine }}</ion-label>
            <ion-icon
              @click="deleteMachine(machine)"
              :icon="closeCircle"
            ></ion-icon>
          </ion-chip>

          <ion-icon
            v-if="oldRentalCouponSending"
            class="width-icon-img-30 ml-5 color-yellow-dark opacity4"
            @click="addMachine"
            :icon="addCircle"
          ></ion-icon>

          <ion-icon
            v-else
            class="width-icon-img-30 ml-5 color-yellow-dark"
            @click="addMachine"
            :icon="addCircle"
          ></ion-icon>
        </div>
      </div>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <div class="align-line-icon-badge mt-5">
        <ion-img
          class="width-icon-img-25"
          src="/assets/icon/tool.svg"
        ></ion-img>
        <div class="align-chip-form ml-10">
          <ion-chip
            color="secondary"
            outline="true"
            v-for="tool in tools"
            :key="tool"
            :disabled="oldRentalCouponSending"
          >
            <ion-label>{{ tool }}</ion-label>
            <ion-icon @click="deleteTool(tool)" :icon="closeCircle"></ion-icon>
          </ion-chip>

          <ion-icon
            v-if="oldRentalCouponSending"
            class="width-icon-img-30 ml-5 color-blue-dark opacity4"
            @click="addTool"
            :icon="addCircle"
          ></ion-icon>

          <ion-icon
            v-else
            class="width-icon-img-30 ml-5 color-blue-dark"
            @click="addTool"
            :icon="addCircle"
          ></ion-icon>
        </div>
      </div>
    </ion-col>
  </ion-row>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonLabel,
  IonImg,
  IonChip,
  IonIcon,
  popoverController,
} from '@ionic/vue';
import { closeCircle, addCircle } from 'ionicons/icons';
import PopoverAddMachine from '@/components/driver/popover/PopoverAddMachine';
import PopoverAddTool from '@/components/driver/popover/PopoverAddTool';

export default {
  name: 'TransferDetails',
  components: {
    IonRow,
    IonCol,
    IonLabel,
    IonImg,
    IonChip,
    IonIcon,
  },
  props: ['machines', 'tools', 'oldRentalCouponSending'],
  setup() {
    return {
      closeCircle,
      addCircle,
    };
  },
  methods: {
    deleteMachine(machineToRemove) {
      let newMachines = this.machines;

      const index = newMachines.indexOf(machineToRemove);
      if (index > -1) {
        newMachines.splice(index, 1);
      }
      this.$emit('update:machines', newMachines);
    },
    deleteTool(toolToRemove) {
      let newTools = this.tools;

      const index = newTools.indexOf(toolToRemove);
      if (index > -1) {
        newTools.splice(index, 1);
      }
      this.$emit('update:tools', newTools);
    },
    async addMachine() {
      if (this.oldRentalCouponSending) {
        return;
      }

      const popover = await popoverController.create({
        component: PopoverAddMachine,
        componentProps: {
          closePopover: newMachine => {
            let newMachines = this.machines;
            newMachines.push(newMachine);
            this.$emit('update:machine', newMachines);
            popover.dismiss();
          },
        },
      });
      await popover.present();

      await popover.onDidDismiss();
    },
    async addTool() {
      if (this.oldRentalCouponSending) {
        return;
      }

      const popover = await popoverController.create({
        component: PopoverAddTool,
        componentProps: {
          closePopover: newTool => {
            let newTools = this.tools;
            newTools.push(newTool);
            this.$emit('update:tools', newTools);
            popover.dismiss();
          },
        },
      });
      await popover.present();

      await popover.onDidDismiss();
    },
  },
};
</script>
